

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.header{
  width: 100%;
  display: flex;
  z-index: 1100;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column; 
  background: linear-gradient(157deg, rgba(104,204,226,1) 0%, rgba(44,65,154,1) 100%);
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.header-icon{
  display: flex;
    position: relative;
    align-items: center;
  min-height: 72px;
}

.next_btn{
  float: right;
  color: white !important;
  background-color: #114470 !important;
}
.action{
  color: white !important;
  background-color: #114470 !important;
  margin-right: 1em !important;
}
.MuiButton-contained.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12)  !important;
}
.MuiButton-containedSecondary,.MuiButton-containedPrimary{
  background-color: #114470 !important;
}
.loading-gif{
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    text-align: left;
    background: rgba(255, 255, 255, 0.7);
    overflow-y: auto;
    padding: 0 20px;
    z-index: 1000;
    transition-delay: 0.25s;
    transition: all 0.5s ease;
    opacity: 1;
    visibility: visible;
    display: block ;
}